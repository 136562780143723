var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFirstDuplicateOption = exports.sortAsc = exports.interpolateStyle = exports.mergeRefs = void 0;

function mergeRefs() {
  var refs = [];

  for (var _i = 0; _i < arguments.length; _i++) {
    refs[_i] = arguments[_i];
  }

  var filteredRefs = refs.filter(Boolean);
  return function (inst) {
    for (var _i = 0, filteredRefs_1 = filteredRefs; _i < filteredRefs_1.length; _i++) {
      var ref = filteredRefs_1[_i];

      if (typeof ref === "function") {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
}

exports.mergeRefs = mergeRefs;
; // IE doesn't seem to get the composite computed value (eg: 'padding',
// 'borderStyle', etc.), so generate these from the individual values.

function interpolateStyle(styles, attr, subattr) {
  if (subattr === void 0) {
    subattr = "";
  } // Title-case the sub-attribute.


  if (subattr) {
    subattr = subattr.replace(subattr[0], subattr[0].toUpperCase());
  }

  return ["Top", "Right", "Bottom", "Left"] // @ts-ignore: (attr + dir + subattr) property cannot be determined at compile time
  .map(function (dir) {
    return styles[attr + dir + subattr];
  }).join(" ");
}

exports.interpolateStyle = interpolateStyle;

function sortAsc(a, b) {
  if (a > b) {
    return 1;
  }

  if (a < b) {
    return -1;
  }

  return 0;
}

exports.sortAsc = sortAsc;

function getFirstDuplicateOption(array) {
  var tracker = {};

  for (var i = 0; i < array.length; i++) {
    if (tracker[array[i].label]) {
      return array[i].label;
    }

    tracker[array[i].label] = true;
  }

  return null;
}

exports.getFirstDuplicateOption = getFirstDuplicateOption;
export default exports;