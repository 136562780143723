import * as _react2 from "react";

var _react = "default" in _react2 ? _react2.default : _react2;

import _utils from "./utils";
var exports = {};

var __assign = exports && exports.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = exports && exports.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = exports && exports.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Hint = void 0;

var react_1 = __importStar(_react);

var utils_1 = _utils;

exports.Hint = function (props) {
  var _a, _b;

  var child = react_1.default.Children.only(props.children);

  if (((_b = (_a = child.type) === null || _a === void 0 ? void 0 : _a.toString()) === null || _b === void 0 ? void 0 : _b.toLowerCase()) !== "input") {
    throw new TypeError("react-autocomplete-hint: 'Hint' only accepts an 'input' element as child.");
  }

  var options = props.options,
      disableHint = props.disableHint,
      allowTabFill = props.allowTabFill,
      allowEnterFill = props.allowEnterFill,
      onFill = props.onFill,
      onHint = props.onHint,
      valueModifier = props.valueModifier;
  var childProps = child.props;
  var mainInputRef = react_1.useRef(null);
  var hintWrapperRef = react_1.useRef(null);
  var hintRef = react_1.useRef(null);

  var _c = react_1.useState(""),
      unModifiedInputText = _c[0],
      setUnmodifiedInputText = _c[1];

  var _d = react_1.useState(""),
      text = _d[0],
      setText = _d[1];

  var _e = react_1.useState(""),
      hint = _e[0],
      setHint = _e[1];

  var _f = react_1.useState(),
      match = _f[0],
      setMatch = _f[1];

  var _g = react_1.useState(),
      changeEvent = _g[0],
      setChangeEvent = _g[1];

  react_1.useEffect(function () {
    if (typeof options[0] === "object") {
      var duplicate = utils_1.getFirstDuplicateOption(options);

      if (duplicate) {
        console.warn("react-autocomplete-hint: \"" + duplicate + "\" occurs more than once and may cause errors. Options should not contain duplicate values!");
      }
    }
  }, [options]);
  react_1.useEffect(function () {
    if (disableHint) {
      return;
    }

    var inputStyle = mainInputRef.current && window.getComputedStyle(mainInputRef.current);
    inputStyle && styleHint(hintWrapperRef, hintRef, inputStyle);
  });

  var getMatch = function (text) {
    if (!text || text === "") {
      return;
    }

    if (typeof options[0] === "string") {
      var match_1 = options.filter(function (x) {
        return x.toLowerCase() !== text.toLowerCase() && x.toLowerCase().startsWith(text.toLowerCase());
      }).sort()[0];
      return match_1;
    } else {
      var match_2 = options.filter(function (x) {
        return x.label.toLowerCase() !== text.toLowerCase() && x.label.toLowerCase().startsWith(text.toLowerCase());
      }).sort(function (a, b) {
        return utils_1.sortAsc(a.label, b.label);
      })[0];
      return match_2;
    }
  };

  var setHintTextAndId = function (text) {
    setText(text);
    var match = getMatch(text);
    var hint;

    if (!match) {
      hint = "";
    } else if (typeof match === "string") {
      hint = match.slice(text.length);
    } else {
      hint = match.label.slice(text.length);
    }

    setHint(hint);
    setMatch(match);
    onHint && onHint(match);
  };

  var handleOnFill = function () {
    if (hint === "" || !changeEvent) {
      return;
    }

    var newUnModifiedText = unModifiedInputText + hint;
    changeEvent.target.value = newUnModifiedText;
    childProps.onChange && childProps.onChange(changeEvent);
    setHintTextAndId("");
    onFill && onFill(match);
    setUnmodifiedInputText(newUnModifiedText);
  };

  var styleHint = function (hintWrapperRef, hintRef, inputStyle) {
    var _a, _b;

    if ((_a = hintWrapperRef === null || hintWrapperRef === void 0 ? void 0 : hintWrapperRef.current) === null || _a === void 0 ? void 0 : _a.style) {
      hintWrapperRef.current.style.fontFamily = inputStyle.fontFamily;
      hintWrapperRef.current.style.fontSize = inputStyle.fontSize;
      hintWrapperRef.current.style.width = inputStyle.width;
      hintWrapperRef.current.style.height = inputStyle.height;
      hintWrapperRef.current.style.lineHeight = inputStyle.lineHeight;
      hintWrapperRef.current.style.boxSizing = inputStyle.boxSizing;
      hintWrapperRef.current.style.margin = utils_1.interpolateStyle(inputStyle, "margin");
      hintWrapperRef.current.style.padding = utils_1.interpolateStyle(inputStyle, "padding");
      hintWrapperRef.current.style.borderStyle = utils_1.interpolateStyle(inputStyle, "border", "style");
      hintWrapperRef.current.style.borderWidth = utils_1.interpolateStyle(inputStyle, "border", "width");
    }

    if ((_b = hintRef === null || hintRef === void 0 ? void 0 : hintRef.current) === null || _b === void 0 ? void 0 : _b.style) {
      hintRef.current.style.fontFamily = inputStyle.fontFamily;
      hintRef.current.style.fontSize = inputStyle.fontSize;
      hintRef.current.style.lineHeight = inputStyle.lineHeight;
    }
  };

  var onChange = function (e) {
    setChangeEvent(e);
    e.persist();
    setUnmodifiedInputText(e.target.value);
    var modifiedValue = valueModifier ? valueModifier(e.target.value) : e.target.value;
    setHintTextAndId(modifiedValue);
    childProps.onChange && childProps.onChange(e);
  };

  var onFocus = function (e) {
    setHintTextAndId(e.target.value);
    childProps.onFocus && childProps.onFocus(e);
  };

  var onBlur = function (e) {
    //Only blur it if the new focus isn't the the hint input
    if ((hintRef === null || hintRef === void 0 ? void 0 : hintRef.current) !== e.relatedTarget) {
      setHintTextAndId("");
      childProps.onBlur && childProps.onBlur(e);
    }
  };

  var ARROWRIGHT = "ArrowRight";
  var TAB = "Tab";
  var ENTER = "Enter";

  var onKeyDown = function (e) {
    var caretIsAtTextEnd = function () {
      // For selectable input types ("text", "search"), only select the hint if
      // it's at the end of the input value. For non-selectable types ("email",
      // "number"), always select the hint.
      var isNonSelectableType = e.currentTarget.selectionEnd === null;
      var caretIsAtTextEnd = isNonSelectableType || e.currentTarget.selectionEnd === e.currentTarget.value.length;
      return caretIsAtTextEnd;
    }();

    if (caretIsAtTextEnd && e.key === ARROWRIGHT) {
      handleOnFill();
    } else if (caretIsAtTextEnd && allowTabFill && e.key === TAB && hint !== "") {
      e.preventDefault();
      handleOnFill();
    } else if (caretIsAtTextEnd && allowEnterFill && e.key === ENTER && hint !== "") {
      e.preventDefault();
      handleOnFill();
    }

    childProps.onKeyDown && childProps.onKeyDown(e);
  };

  var onHintClick = function (e) {
    var _a;

    var hintCaretPosition = e.currentTarget.selectionEnd || 0; // If user clicks the position before the first character of the hint, 
    // move focus to the end of the mainInput text

    if (hintCaretPosition === 0) {
      (_a = mainInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
      return;
    }

    if (!!hint && hint !== "") {
      handleOnFill();
      setTimeout(function () {
        var _a, _b;

        (_a = mainInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        var caretPosition = text.length + hintCaretPosition;
        (_b = mainInputRef.current) === null || _b === void 0 ? void 0 : _b.setSelectionRange(caretPosition, caretPosition);
      }, 0);
    }
  };

  var childRef = react_1.cloneElement(child).ref;
  var mainInput = react_1.cloneElement(child, __assign(__assign({}, childProps), {
    onChange: onChange,
    onBlur: onBlur,
    onFocus: onFocus,
    onKeyDown: onKeyDown,
    ref: childRef && typeof childRef !== "string" ? utils_1.mergeRefs(childRef, mainInputRef) : mainInputRef
  }));
  return react_1.default.createElement("div", {
    className: "rah-input-wrapper",
    style: {
      position: "relative"
    }
  }, disableHint ? child : react_1.default.createElement(react_1.default.Fragment, null, mainInput, react_1.default.createElement("span", {
    className: "rah-hint-wrapper",
    ref: hintWrapperRef,
    style: {
      display: "flex",
      pointerEvents: "none",
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
      color: "rgba(0, 0, 0, 0.35)",
      position: "absolute",
      top: 0,
      left: 0
    }
  }, react_1.default.createElement("span", {
    className: "rah-text-filler",
    style: {
      visibility: "hidden",
      pointerEvents: "none",
      whiteSpace: "pre"
    }
  }, text), react_1.default.createElement("input", {
    className: "rah-hint",
    ref: hintRef,
    onClick: onHintClick,
    style: {
      pointerEvents: !hint || hint === "" ? "none" : "visible",
      background: "transparent",
      width: "100%",
      outline: "none",
      border: "none",
      boxShadow: "none",
      padding: 0,
      margin: 0,
      color: "rgba(0, 0, 0, 0.30)",
      caretColor: "transparent"
    },
    defaultValue: hint,
    tabIndex: -1
  }))));
};

export default exports;
export const __esModule = exports.__esModule,
      Hint = exports.Hint;